import { combineReducers } from 'redux';
import apiCallsInProgress from './apiStatusReducer';
import modal from './modalReducer';
import presets from './presetsReducer';
import searchResult from './searchReducer';
import user from './userReducer';
import showLightbox from './showLightboxReducer';
import userCart from '../userCart/reducer';
import receivingMethod from './receivingMethodReducer';
import restaurants from '../restaurants/reducer';
import favorites from '../favorites/reducer';
import caterings from '../catering/reducer';
import paymentsMethod from './paymentsMethodsReducer'

const rootReducer = combineReducers({
  apiCallsInProgress,
  modal,
  presets,
  userCart,
  searchResult,
  user,
  showLightbox,
  receivingMethod,
  restaurants,
  favorites,
  caterings,
  paymentsMethod
});

export default rootReducer;
