import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import initialState from "redux/reducers/initialState";
import { loadPaymentsMethodList } from "redux/actions/paymentActions";
import PaymentCard from "./card";

const PaymentMethods = () => {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);

  const paymentsMethods = useSelector(state => state.paymentsMethod);
  useEffect(() => {
    const fetchData = async () => {
      if (paymentsMethods === initialState.paymentsMethods) {
        await dispatch(loadPaymentsMethodList());
      }
    };
    fetchData();
  }, []);
  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev);
  };
  return (
    <div className="container">
      <div className="main-header">
        <h2>Payment Methods</h2>
        {/* <button
                    className="yellow-button"
                    data-toggle="modal"
                    data-target="#paymentModal"
                >
                    Add New Card
                </button> */}
      </div>
      <div className="card-grid">
        {paymentsMethods.map((card) => (
          <PaymentCard key={card.id} card={card} />
        ))}
      </div>
      <div class="modal fade" id="paymentModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <button
              class="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
            <div class="modal-header justify-content-center">
              <h3 className="m-0">Please fill in the below fields</h3>
            </div>
            <div class="modal-body modal-payment-method pb-3">
              <form>
                <h3 class="hide-mobile form-heading h6 mb-3">Payment Method</h3>
                <div class="labeled-group floating">
                  <div class="form-row mb-3">
                    <div class="col">
                      <div className="custom-checkbox-input">
                        <div
                          className={`circle-check ${isChecked ? "checked" : ""
                            }`}
                          onClick={handleCheckboxChange}
                        >
                          {isChecked ? (
                            <span>
                              <img src={'assets/img/payment/checked.svg'} />
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <input
                          type="text"
                          placeholder="Debit/Credit Card"
                          className="input-field"
                          onClick={handleCheckboxChange}
                          readOnly
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div className="custom-checkbox-input">
                        <div
                          className={`circle-check ${isChecked ? "checked" : ""
                            }`}
                          onClick={handleCheckboxChange}
                        >
                          {isChecked ? (
                            <span>
                              <img src={'assets/img/payment/checked.svg'} />
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <input
                          type="text"
                          placeholder="EFT Payment"
                          className="input-field"
                          onClick={handleCheckboxChange}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <small>
                      <label className="mb-0" for="inputEmail4">
                        First Name
                      </label>
                    </small>
                    <input type="text" class="form-control" id="inputEmail4" />
                  </div>
                  <div class="form-group col-md-6">
                    <small>
                      <label className="mb-0" for="inputEmail4">
                        Last Name
                      </label>
                    </small>
                    <input
                      type="text"
                      class="form-control"
                      id="inputPassword4"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <small>
                      <label className="mb-0" for="inputEmail4">
                        Email
                      </label>
                    </small>
                    <div className="position-relative email-icon-wrap">
                      <input
                        type="Email"
                        class="form-control"
                        id="inputEmail4"
                      />
                      <img className="position-absolute" src={'assets/img/payment/email.svg'} />
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <small>
                      <label className="mb-0" for="inputEmail4">
                        Street Address
                      </label>
                    </small>
                    <div className="position-relative">
                      <input
                        type="text"
                        class="form-control"
                        id="inputEmail4"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <small>
                      <label className="mb-0" for="inputEmail4">
                        State/Province
                      </label>
                    </small>
                    <div className="position-relative">
                      <select class="custom-select" id="inputGroupSelect01">
                        <option selected>State/Province</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <small>
                      <label className="mb-0" for="inputEmail4">
                        City
                      </label>
                    </small>
                    <div className="position-relative">
                      <select class="custom-select" id="inputGroupSelect01">
                        <option selected>City</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <small>
                      <label className="mb-0" for="inputEmail4">
                        Zip/Postal Code
                      </label>
                    </small>
                    <div className="position-relative">
                      <div className="position-relative">
                        <select class="custom-select" id="inputGroupSelect01">
                          <option selected>Zip/Postal Code</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <small>
                      <label className="mb-0" for="inputEmail4">
                        Phone number
                      </label>
                    </small>
                    <div className="position-relative">
                      <input
                        type="text"
                        class="form-control"
                        id="inputEmail4"
                      />
                    </div>
                  </div>
                </div>
                <div class="btn btn-primary btn-block mt-2">Submit</div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
