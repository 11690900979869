import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectStripe } from 'react-stripe-elements';
import { priceDecimalFormatter } from 'formatter/preset.formatter';
import CardSection from './CardSection';

function CheckoutForm({
  orderHandler,
  loadingHandler,
  elements,
  stripe,
  totalPrice,
  setSaveCard,
  isEft,
}) {
  const handleSubmit = async ev => {
    ev.preventDefault();
    loadingHandler();
    try {
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement('card'),
      });
      orderHandler(result.paymentMethod.id);
    } catch (err) {
      orderHandler(err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Render either the Bank or Card section based on isEft flag */}
      <CardSection />
      <br />
      <div className="custom-control custom-checkbox custom-checkbox-100">
        <input
          className="custom-control-input"
          type="checkbox"
          id="saveCard"
          onChange={() => setSaveCard(prev => !prev)}
        />
        <label className="custom-control-label" htmlFor="saveCard">
          <span>
            By checking this box, you agree to save your card for later. This
            will speed up your checkout process.
          </span>
        </label>
      </div>
      <br />
      <button className="btn btn-block btn-primary" type="submit">
        Pay ${priceDecimalFormatter(totalPrice)}
      </button>
    </form>
  );
}

CheckoutForm.propTypes = {
  orderHandler: PropTypes.func,
  loadingHandler: PropTypes.func,
  setSaveCard: PropTypes.func,
  stripe: PropTypes.shape({
    createPaymentMethod: PropTypes.func.isRequired,
  }).isRequired,
  elements: PropTypes.shape({
    getElement: PropTypes.func.isRequired,
  }).isRequired,
  totalPrice: PropTypes.number.isRequired,
  isEft: PropTypes.bool.isRequired,
};

CheckoutForm.defaultProps = {
  orderHandler: () => {},
  loadingHandler: () => {},
  setSaveCard: () => {},
};

export default injectStripe(CheckoutForm);
