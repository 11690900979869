/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// eslint-disable-next-line import/no-cycle
import { finalizingCateringOrderAction } from 'redux/actions/paymentActions';
import { useHistory } from 'react-router-dom';
import LoadingAnimation from 'components/common/LoadingAnimation';
import { store } from 'react-notifications-component';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const EftFormModal = ({ show, onClose, cateringOrder, clearCateringOrder }) => {
  const history = useHistory();
  const options = {
    clientSecret: cateringOrder?.clientSecret,
    fields: {
      billingDetails: {
        name: 'never',
        email: 'never',
      },
    },
  };

  // eslint-disable-next-line react/prop-types
  const userName = cateringOrder?.fullName;
  // eslint-disable-next-line react/prop-types
  const userEmail = cateringOrder?.email;

  const RenderPaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [, setIsLoading] = useState(true);
    const onSuccess = () => {
      history.push('/checkout-completed');
    };

    const handleSubmit = async () => {
      if (!stripe || !elements) {
        return;
      }
      const clientSecret = cateringOrder?.clientSecret;
      const { setupIntent, error } = await stripe.confirmAcssDebitSetup(
        clientSecret,
        {
          payment_method: {
            billing_details: {
              name: userName,
              email: userEmail,
            },
          },
        },
      );

      if (error) {
        onClose();
        store.addNotification({
          message: error.message,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 8000,
          },
        });
      }
      if (setupIntent.status === 'succeeded') {
        try {
          const paymentMethodId = setupIntent.payment_method;
          const purchaseId = cateringOrder?.purchaseId;
          await finalizingCateringOrderAction(
            paymentMethodId,
            purchaseId,
            false,
            onSuccess,
            setIsLoading,
          );
          await clearCateringOrder();
        } catch (error) {
          onClose();
          store.addNotification({
            message: error,
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 8000,
            },
          });
        }
      }
    };

    return (
      <>
        <div className="row eft-modal">
          <div className="col-xl-12">
            <div className="labeled-group floating">
              <input
                id="Field-nameInput"
                className="form-control"
                type="text"
                name="full_name"
                required="required"
                value={userName}
                disabled
              />
              <label>Full Name</label>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="labeled-group floating">
              <input
                id="Field-emailInput"
                className="form-control"
                type="email"
                name="email"
                value={userEmail}
                required="required"
                disabled
              />
              <label>Email</label>
            </div>
          </div>
        </div>
        <PaymentElement options={options} />
        <button
          onClick={handleSubmit}
          disabled={!stripe}
          className="btn btn-block btn-primary"
        >
          Confirm and Proceed to Payment
        </button>
      </>
    );
  };

  return (
    <Modal show={show} onHide={onClose}>
      <button
        className="close"
        type="button"
        data-dismiss="modal"
        aria-label="Close"
        onClick={onClose}
      />
      <Modal.Header>
        <h3>Verify Your Details</h3>
      </Modal.Header>
      <Modal.Body className="pb-5">
        {cateringOrder && cateringOrder.clientSecret ? (
          <Elements stripe={stripePromise} options={options}>
            <RenderPaymentForm />
          </Elements>
        ) : (
          <LoadingAnimation />
        )}
      </Modal.Body>
    </Modal>
  );
};

EftFormModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  orderHandler: PropTypes.func,
  loadingHandler: PropTypes.func,
  setSaveCard: PropTypes.func,
  totalPrice: PropTypes.number.isRequired,
  clearCateringOrder: PropTypes.func.isRequired,
  cateringOrder: PropTypes.shape({
    savedCards: PropTypes.arrayOf(
      PropTypes.objectOf({
        name: PropTypes.string,
        payment_method_id: PropTypes.string,
      }),
    ),
    clientSecret: PropTypes.string,
    purchaseId: PropTypes.number.isRequired,
  }).isRequired,
};

EftFormModal.defaultProps = {
  show: true,
  onClose: () => {},
  orderHandler: () => {},
  loadingHandler: () => {},
  setSaveCard: () => {},
};

export default EftFormModal;
