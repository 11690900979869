import { fetchAPI } from './apiUtils';

export const PAYMENT_INTENT_URL = 'api/v2/payment_intent';
export const PAYMENT_METHODS_URL = 'api/v2/user_payment_methods';
export const PAYMENT_DEFAULT_METHOD_URL = 'api/v2/payment_methods/';

export function getPaymentIntent(formData) {
  return fetchAPI(PAYMENT_INTENT_URL, 'POST', formData);
}
export function getPaymentMethods() {
  return fetchAPI(PAYMENT_METHODS_URL, 'GET');
}
export function makeDefaultPaymentMethod(id) {
  return fetchAPI(PAYMENT_DEFAULT_METHOD_URL + id + '/set_as_default', 'POST');
}

export function postPaymentResult(intentID, formData) {
  return fetchAPI(`${PAYMENT_INTENT_URL}/${intentID}`, 'PUT', formData);
}
