export const BEGIN_API_CALL = 'BEGIN_API_CALL';
export const API_CALL_ERROR = 'API_CALL_ERROR';

// JWT Authentication
export const BEGIN_API_AUTH = 'BEGIN_API_AUTH';
export const API_AUTH_ERROR = 'API_AUTH_ERROR';
export const API_AUTH_SUCCESS = 'API_AUTH_SUCCESS';

// Fetching preset list
export const FETCH_PRESET_LIST_SUCCESS = 'FETCH_PRESET_LIST_SUCCESS';
export const FETCH_PRESET_LIST_FAILED = 'FETCH_PRESET_LIST_FAILED';


// Fetching payment list
export const FETCH_PAYMENT_METHODS_LIST_SUCCESS = 'FETCH_PAYMENT_METHODS_LIST_SUCCESS';
export const FETCH_PAYMENT_METHODS_LIST_FAILED = 'FETCH_PAYMENT_METHODS_LIST_FAILED';

// Fetching preset detail
export const FETCH_PRESET_DETAIL_SUCCESS = 'FETCH_PRESET_DETAIL_SUCCESS';
export const FETCH_PRESET_DETAIL_FAILED = 'FETCH_PRESET_DETAIL_SUCCESS';

// Modals
export const SHOW_SIGNIN_MODAL_SUCCESS = 'SHOW_SIGNIN_MODAL_SUCCESS';
export const SHOW_SIGNUP_MODAL_SUCCESS = 'SHOW_SIGNUP_MODAL_SUCCESS';
export const SHOW_CONFIRM_MODAL_SUCCESS = 'SHOW_CONFIRM_MODAL_SUCCESS';
export const SHOW_CONFIRM_MODAL_FAILED = 'SHOW_CONFIRM_MODAL_FAILED';
export const SHOW_PASSWORD_MODAL_SUCCESS = 'SHOW_PASSWORD_MODAL_SUCCESS';
export const SHOW_PASSWORD_MODAL_FAILED = 'SHOW_PASSWORD_MODAL_FAILED';

export const CLOSE_SIGNIN_SIGNUP_MODAL_SUCCESS = 'CLOSE_SIGNIN_MODAL_SUCCESS';

// Auth
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SINGUP_FAILED = 'USER_SINGUP_FAILED';

export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAILED = 'USER_SIGNIN_FAILED';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const USER_SIGNOUT_SUCCESS = 'USER_SIGNOUT_SUCCESS';

// TopBar Search
export const TOP_BAR_SEARCH_RESULT_SUCCESS = 'TOP_BAR_SEARCH_RESULT_SUCCESS';
export const TOP_BAR_SEARCH_RESULT_FAILED = 'TOP_BAR_SEARCH_RESULT_FAILED';
export const TOP_BAR_SEARCH_INPUT_FOCUSED = 'TOP_BAR_SEARCH_INPUT_FOCUSED';

// show light Box
export const SHOW_LIGHTBOX_SUCCESS = 'SHOW_LIGHTBOX_SUCCESS';

// receiving method
export const UPDATE_RECEIVING_METHOD = 'UPDATE_RECEIVING_METHOD';
