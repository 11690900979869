import * as types from './actionTypes';
import * as paymentApi from '../../api/paymentApi';
import { beginApiCall, apiCallError } from './apiStatusActions';
import { finalizeCateringOrder } from 'api/cateringApi';

export function loadPaymentsMethodListSuccess(paymentsMethods) {
  return { type: types.FETCH_PAYMENT_METHODS_LIST_SUCCESS, paymentsMethods };
}

export function loadPaymentsMethodList() {
  return async dispatch => {
    dispatch(beginApiCall());
    try {
      const paymentsMethods = await paymentApi.getPaymentMethods();
      const action = loadPaymentsMethodListSuccess(paymentsMethods?.sort((a, b) => {
        return (b?.isDefault === true) - (a?.isDefault === true);
      }));
      dispatch(action);
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export const finalizingCateringOrderAction = async (
  paymentMethodId,
  purchaseId,
  saveCard,
  onSuccess,
  setIsLoading,
) => {
  try {
    const data = await finalizeCateringOrder(
      paymentMethodId,
      purchaseId,
      saveCard,
    );
    onSuccess();
    setIsLoading(false);
  } catch (e) {
    const { error } = await e.response.json();
    throw error;
  }
};
