export default {
  apiCallsInProgress: 0,
  modal: {
    signInModalShown: false,
    signUpModalShown: false,
    modalHeader: '',
    confirmModalShown: false,
    modalText: '',
    resetPasswordModalShown: false,
  },
  user: {
    user: {},
  },
  cart: {},
  presets: {}, // will be used on homepageak
  paymentsMethods: [], // will be used on payment methods page
  searchResult: {
    q: '',
    presets: [],
    menus: [],
    chefs: [],
    inputFocused: false,
  },
  showLightbox: true,
  receivingMethod: 'delivery',
};
