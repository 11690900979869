/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { connect } from 'react-redux';
import * as yup from 'yup';
import classnames from 'classnames';
// import { store } from 'react-notifications-component';
import sumBy from 'lodash/sumBy';
import { SERVING_STYLE } from 'consts/checkout';
import {
  REQUIRED_VALIDATION,
  REQUIRED_SIGNIN_FOR_PAYMENT,
  INVALID_EMAIL,
  INVALID_INTEGER,
  DEFAULT_TIME_FORMAT,
  DEFAULT_DATE_FORMAT_SAFARI,
} from 'consts/form';
import FormError from 'components/common/FormError';
import DatePickerField from 'components/common/DatePickerField';
import AddressPickerField from 'components/common/AddressPickerField';
import CheckoutInfo from 'components/common/CheckoutInfo';
import { priceDecimalFormatter } from 'formatter/preset.formatter';
import {
  getPresetSubTotalPrice,
  buildPaymentIntentPresetItems,
  getPresetItemQuantity,
} from 'utils/preset.util';
import { withRouter } from 'react-router-dom';
import LoadingAnimation from 'components/common/LoadingAnimation';
import { setInitialValues } from 'components/common/DeliveryInfoForm';
import { showSignInModal } from 'redux/actions/modalActions';
import {
  cateringOrder as fetchCateringOrder,
  cateringOrderUpdate,
  cateringOrderFinalize,
  clearCateringOrder,
} from 'redux/catering/actions';
import { signoutRequest } from 'redux/actions/authActions';
// import OrderFrequency from 'components/common/OrderFrequency';
// import moment from 'moment-timezone';
import { format } from 'date-fns';
import StripeFormModal from './StripeFormModal';
import EftFormModal from './EftFormModal';
import './style.scss';

const validationSchema = yup.object().shape({
  address: yup.string().required(REQUIRED_VALIDATION),
  date: yup
    .date()
    .typeError(REQUIRED_VALIDATION)
    .required(REQUIRED_VALIDATION)
    .notOneOf([null], REQUIRED_VALIDATION),
  time: yup
    .date()
    .typeError(REQUIRED_VALIDATION)
    .required(REQUIRED_VALIDATION)
    .notOneOf([null], REQUIRED_VALIDATION),
  notes: yup.string(),
  servingStyle: yup
    .mixed()
    .oneOf([SERVING_STYLE.INDIVIDUAL, SERVING_STYLE.FAMILY]),
  cutleryNeeded: yup.boolean(),
  cutleryCount: yup
    .number()
    .min(0, INVALID_INTEGER)
    .nullable()
    .typeError(INVALID_INTEGER),
  firstName: yup.string().required(REQUIRED_VALIDATION),
  lastName: yup.string().required(REQUIRED_VALIDATION),
  companyName: yup.string().required(REQUIRED_VALIDATION),
  phoneNumber: yup.string().required(REQUIRED_VALIDATION),
  emailAddress: yup
    .string()
    .typeError(INVALID_EMAIL)
    .email(INVALID_EMAIL)
    .required(REQUIRED_VALIDATION),
});

function Checkout({
  history,
  location: { state },
  user,
  showSignInModal,
  cateringOrder,
  fetchCateringOrder,
  cateringOrderUpdate,
  cateringOrderFinalize,
  clearCateringOrder,
  receivingMethod,
}) {
  const deliveryInfoDefaultValues = setInitialValues();
  const { preset, deliveryPriceCents, checkoutData, restaurantAddress } = state;
  const [isLoading, setLoading] = useState(true);
  const [addressPickerFocused, setAddressPickerFocused] = useState(false);
  const [datePickerFocused, setDatePickerFocused] = useState(false);
  const [timePickerFocused, setTimePickerFocused] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const [showStripeFromModal, setShowStripeFormModal] = useState(false);
  const [currentModel, setCurrentModel] = useState(null);
  const [stripeErrorMessage, setStripeErrorMessage] = useState('');

  const subTotal = getPresetSubTotalPrice(checkoutData);
  const totalPrice =
    receivingMethod === 'delivery'
      ? (subTotal + deliveryPriceCents) * (1 + preset.taxRate)
      : subTotal * (1 + preset.taxRate);
  const isSignedIn = user && user.confirmed;
  const initialValues = {
    servingStyle: SERVING_STYLE.INDIVIDUAL,
    address: deliveryInfoDefaultValues.address, // get these fields from the user info saved on backend side
    address2: user.address2 || '',
    date: deliveryInfoDefaultValues.date,
    time: deliveryInfoDefaultValues.time,
    notes: user.defaultDeliveryInstructions || '',
    cutleryNeeded: false,
    cutleryCount: 0,
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    companyName: user.companyName || '',
    phoneNumber: user.phone || '',
    emailAddress: user.email || '',
  };

  const getPaymentKey = async (checkoutData, preset, form, eft = false) => {
    try {
      setLoading(true);

      const {
        firstName,
        lastName,
        companyName,
        phoneNumber,
        emailAddress,
        cutleryNeeded,
        cutleryCount,
        servingStyle,
      } = form;
      const cart = {
        presetId: preset.id,
        presetName: preset.name,
        numCutlery: cutleryNeeded ? parseInt(cutleryCount, 10) : 0,
        firstName,
        lastName,
        companyName,
        phoneNumber,
        email: emailAddress,
        servingStyle,
        presetItems: buildPaymentIntentPresetItems(checkoutData),
        receivingMethod,
      };

      const dataTime =
        form && form.date && form.time
          ? new Date(
            `${format(form.date, DEFAULT_DATE_FORMAT_SAFARI)} ${format(
              form.time,
              DEFAULT_TIME_FORMAT,
            )} ${preset?.presetLocation?.currentTime?.slice(-6)}`,
          )
          : '';
      if (receivingMethod === 'delivery') {
        cart.deliveryAddress = form ? form.address : '';
        cart.deliverySuiteAddress = form ? form.address2 : '';
        cart.deliveryDate = dataTime;
        cart.deliveryTime = dataTime;
        cart.deliveryInstruction = form ? form.notes : '';
      } else {
        cart.pickupDate = dataTime;
        cart.pickupTime = dataTime;
        cart.pickupInstruction = form ? form.notes : '';
      }
      if (cateringOrder) {
        cateringOrderUpdate(cart, cateringOrder?.purchaseId, eft);
      } else {
        fetchCateringOrder(cart, eft);
      }
    } catch (e) {
      // alert(e.message);
    } finally {
      setLoading(false);
    }
  };

  // const postPaymentKey = async (stripeInfo, result) => {
  //   let postRes = {};
  //   try {
  //     const formData = {
  //       status: result.paymentIntent
  //         ? STRIPE_STYLE.STRIPE_PAYMENT_SUCCESS
  //         : result.error.code,
  //     };
  //     postRes = await postPaymentResult(stripeInfo.intentId, formData);
  //   } catch (e) {
  //     postRes = {
  //       msg: e,
  //     };
  //   } finally {
  //     setLoading(false);
  //   }
  //   return postRes;
  // };
  useEffect(() => {
    clearCateringOrder();
    getPaymentKey(checkoutData, preset, null);
    return () => clearCateringOrder();
  }, []);

  useEffect(() => {
    const newModel = cateringOrder?.isEft ? 'eft' : 'card';
    if (currentModel && currentModel !== newModel) {
      clearCateringOrder();
      getPaymentKey(checkoutData, preset, null);
    }
  }, [currentModel]);

  const onSubmit = async form => {
    if (!isSignedIn) {
      showSignInModal(REQUIRED_SIGNIN_FOR_PAYMENT);
      return;
    }
    const showEft = form.paymentType === 'eft';

    await getPaymentKey(
      checkoutData.map(item => ({
        ...item,
        ...(item.menuId && { id: `Additional_${item.menuId}` }),
      })),
      preset,
      form,
      showEft,
    );

    setCurrentModel(showEft ? 'eft' : 'card');

    setShowStripeFormModal(true);
  };

  const orderHandler = async result => {
    setCurrentModel(null);
    if (result && result.error) {
      setLoading(false);
      setStripeErrorMessage(result.error.message);
    } else {
      try {
        await cateringOrderFinalize(
          result,
          cateringOrder?.purchaseId,
          saveCard,
        );
        await clearCateringOrder();
        await history.push('/checkout-completed');
      } catch (e) {
        console.error(e);
      }
    }
  };
  const totalQuantity = useMemo(() => {
    if (!checkoutData || checkoutData.length === 0) {
      return 0;
    }
    return sumBy(checkoutData, presetItem => getPresetItemQuantity(presetItem));
  }, [checkoutData]);

  // function handleSaveOrderFrequency(data) {
  //   store.addNotification({
  //     message: '//TODO where should this data go?',
  //     type: 'danger',
  //     insert: 'top',
  //     container: 'top-right',
  //     animationIn: ['animated', 'bounceIn'],
  //     animationOut: ['animated', 'fadeOut'],
  //     dismiss: {
  //       duration: 4000,
  //     },
  //   });
  // }

  return (
    <main>
      {isLoading && <LoadingAnimation />}
      {/* <CheckoutCompleted /> -- used when checkout completed */}
      <div className="container checkout py-5 mt-xl-5 mb-5">
        <Formik
          {...{ initialValues, validationSchema }}
          validateOnChange
          validateOnBlur
          onSubmit={onSubmit}
        >
          {({
            setFieldValue,
            values,
            touched,
            handleBlur,
            setFieldTouched,
          }) => (
            <Form className="row">
              <div className="col-xl-8 pr-xl-5">
                <h2>Complete your order</h2>
                <h3 className="mt-4 mb-3">Your details</h3>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="labeled-group floating">
                      <Field
                        type="input"
                        className="form-control"
                        name="firstName"
                      />
                      <FormError name="firstName" />
                      <label
                        htmlFor="firstName"
                        className={classnames({
                          'label-focused': values.firstName,
                        })}
                      >
                        First Name
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="labeled-group floating">
                      <Field
                        type="input"
                        className="form-control"
                        name="lastName"
                      />
                      <FormError name="lastName" />
                      <label
                        htmlFor="lastName"
                        className={classnames({
                          'label-focused': values.lastName,
                        })}
                      >
                        Last Name
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="labeled-group floating">
                      <Field
                        type="input"
                        className="form-control"
                        name="companyName"
                      />
                      <FormError name="companyName" />
                      <label
                        htmlFor="companyName"
                        className={classnames({
                          'label-focused': values.companyName,
                        })}
                      >
                        Company Name
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="labeled-group floating">
                      <Field
                        type="input"
                        className="form-control"
                        name="phoneNumber"
                      />
                      <FormError name="phoneNumber" />
                      <label
                        htmlFor="phoneNumber"
                        className={classnames({
                          'label-focused': values.phoneNumber,
                        })}
                      >
                        Phone Number
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="labeled-group floating">
                      <Field
                        className="form-control"
                        name="emailAddress"
                        type="email"
                      />
                      <FormError name="emailAddress" />
                      <label
                        htmlFor="emailAddress"
                        className={classnames({
                          'label-focused': values.emailAddress,
                        })}
                      >
                        Email
                      </label>
                    </div>
                  </div>
                </div>

                {receivingMethod === 'delivery' && (
                  <>
                    <h3 className="mt-5 mb-3">Delivery details</h3>
                    <div className="row">
                      <div className="col-xl-8">
                        <div className="labeled-group floating">
                          <AddressPickerField
                            id="checkout-address"
                            name="address"
                            value={values.address}
                            onChange={setFieldValue}
                            onFocus={() => {
                              setAddressPickerFocused(true);
                              setFieldTouched('address');
                            }}
                            onBlur={e => {
                              setAddressPickerFocused(false);
                              handleBlur(e);
                            }}
                          />
                          <label
                            htmlFor="checkout-address"
                            className={classnames({
                              'label-focused':
                                addressPickerFocused || values.address,
                            })}
                          >
                            Delivery Address
                          </label>
                          <FormError name="address" />
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div className="labeled-group floating">
                          <Field
                            type="input"
                            className="form-control"
                            name="address2"
                          />
                          <FormError name="address2" />
                          <label
                            htmlFor="lastName"
                            className={classnames({
                              'label-focused': values.address2,
                            })}
                          >
                            Suite #
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {receivingMethod === 'pickup' && (
                  <>
                    <h3 className="mt-5 mb-3">Pick up details</h3>
                    <div className="labeled-group floating">
                      <input
                        type="text"
                        readOnly
                        className="form-control form-control-plaintext"
                        value={restaurantAddress}
                      />
                      <label
                        htmlFor="checkout-address"
                        className={classnames({
                          'label-focused': true,
                        })}
                      >
                        Pick up Address
                      </label>
                      <FormError name="address" />
                    </div>
                  </>
                )}

                <div className="row">
                  <div className="col-xl-6">
                    <div className="labeled-group floating">
                      <div className="input-with-icons mb-2 ii-right">
                        <DatePickerField
                          id="checkout-date"
                          name="date"
                          value={values.date}
                          onChange={setFieldValue}
                          onFocus={() => {
                            setDatePickerFocused(true);
                            setFieldTouched('date');
                          }}
                          onBlur={e => {
                            setDatePickerFocused(false);
                            handleBlur(e);
                          }}
                        />
                        <FormError name="date" />
                        <label
                          htmlFor="checkout-date"
                          className={classnames({
                            'label-focused': datePickerFocused || values.date,
                          })}
                        >
                          {receivingMethod === 'pickup'
                            ? 'Pick up Date'
                            : 'Delivery Date'}
                        </label>
                        <div className="input-append">
                          <svg className="icon icon-calender">
                            <use xlinkHref="#spriteIcon-calender" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="labeled-group floating">
                      <div className="input-with-icons mb-2 ii-right">
                        <DatePickerField
                          id="checkout-time"
                          name="time"
                          showTimeSelect
                          showTimeSelectOnly
                          value={values.time}
                          onChange={setFieldValue}
                          onFocus={() => {
                            setTimePickerFocused(true);
                            setFieldTouched('time');
                          }}
                          onBlur={e => {
                            setTimePickerFocused(false);
                            handleBlur(e);
                          }}
                        />
                        <FormError name="time" />
                        {state?.preset?.presetLocation?.timeZone && (
                          <div className="labeled-group__danger">
                            This is based on{' '}
                            {state?.preset?.presetLocation?.timeZone}
                          </div>
                        )}
                        <label
                          htmlFor="checkout-time"
                          className={classnames({
                            'label-focused': timePickerFocused || values.time,
                          })}
                        >
                          {receivingMethod === 'pickup'
                            ? 'Pick up Time'
                            : 'Delivery Time'}
                        </label>
                        <div className="input-append">
                          <svg className="icon icon-clock">
                            <use xlinkHref="#spriteIcon-clock" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="labeled-group floating">
                  <Field
                    component="textarea"
                    className="form-control"
                    name="notes"
                    id="checkout-notes"
                    rows={2}
                  />
                  <FormError name="notes" />
                  <label
                    htmlFor="checkout-notes"
                    className={classnames({
                      'label-focused': touched.notes || values.notes,
                    })}
                  >
                    Additional{' '}
                    {receivingMethod === 'pickup' ? 'pick up' : 'delivery'}{' '}
                    instructions
                  </label>
                </div>
                <FormError name="cutleryCount" />
                <div className="checkout--options-fields mb-xl-5">
                  <div className="left">
                    <div className="custom-control custom-radio custom-control-inline">
                      <Field
                        className="custom-control-input"
                        id="individually"
                        type="radio"
                        name="servingStyle"
                        value={SERVING_STYLE.INDIVIDUAL}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="individually"
                      >
                        Serve Individually Packaged
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <Field
                        className="custom-control-input"
                        id="family"
                        type="radio"
                        name="servingStyle"
                        value={SERVING_STYLE.FAMILY}
                      />
                      <label className="custom-control-label" htmlFor="family">
                        Serve Family Style
                      </label>
                    </div>
                  </div>
                  <div className="right">
                    <div className="custom-control custom-checkbox">
                      <Field
                        className="custom-control-input"
                        id="checkout-cutlery"
                        type="checkbox"
                        name="cutleryNeeded"
                        checked={values.cutleryNeeded}
                        onChange={e => {
                          const value = e.target.checked;
                          setFieldValue('cutleryNeeded', value);
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkout-cutlery"
                      >
                        <span>I need cutlery</span>
                      </label>
                    </div>
                    <div className="ml-4">
                      <div className="labeled-group" style={{ width: 80 }}>
                        <Field
                          type="input"
                          className="form-control"
                          name="cutleryCount"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="card checkout--order-details order-details mt-5 mb-4">
                  <div className="card-header text-muted">
                    Order Details{' '}
                    {totalQuantity > 0 && (
                      <span className="badge badge-primary badge-pill">
                        {totalQuantity}
                      </span>
                    )}
                  </div>
                  {stripeErrorMessage && (
                    <div className="alert alert-danger d-flex align-items-center">
                      <svg className="icon icon-warning">
                        <use xlinkHref="#spriteIcon-warning" />
                      </svg>
                      <span>{stripeErrorMessage}</span>
                    </div>
                  )}
                  <CheckoutInfo
                    readOnly
                    preset={preset}
                    deliveryPriceCents={deliveryPriceCents}
                    checkoutData={checkoutData}
                  />
                  <div className="card-footer p-3">
                    {/* <div className="labeled-group floating">
                        <select className="form-control lg" name="pay_method">
                          <option value={1342} selected>
                            Visa debit card ending in ••• 1342
                          </option>
                        </select>
                        <label>Pay with</label>
                      </div> */}
                    <button
                      type="submit"
                      name="paymentMethod"
                      value="card"
                      onClick={() => setFieldValue('paymentType', 'card')}
                      className="btn btn-block btn-primary"
                    >
                      Pay ${priceDecimalFormatter(totalPrice)}
                      <img src="/assets/img/debit-card-color.png" 
                          width={30}
                          height={30}
                          alt='eft icon'
                          className=' ml-2'
                        />
                    </button>
                    <div className='d-none'>
                      <div className='position-relative my-4'>
                        <hr/>
                        <span className='position-absolute or-seperator bg-white p-2'>OR</span>
                      </div>

                      <button
                        type="submit"
                        name="paymentMethod"
                        value="eft"
                        onClick={() => setFieldValue('paymentType', 'eft')}
                        className="btn btn-block border-primary"
                      >
                        <span>
                          Pay with EFT
                        </span>
                        <span className='d-flex align-items-center'>
                          <img src="/assets/img/interac.png"
                            width={30}
                            height={30}
                            alt='eft icon'
                            className=' ml-2'
                          />
                          <small className='font-bold'>Verified</small>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* <OrderFrequency
                  handleSaveOrderFrequency={handleSaveOrderFrequency}
                /> */}
              </div>
            </Form>
          )}
        </Formik>

        <StripeFormModal
          show={currentModel === 'card'}
          onClose={() => {
            setCurrentModel(null);
          }}
          orderHandler={orderHandler}
          loadingHandler={() => {
            setLoading(true);
          }}
          clearCateringOrder={clearCateringOrder}
          cateringOrder={cateringOrder}
          totalPrice={totalPrice}
          setSaveCard={setSaveCard}
        />
        <EftFormModal
          show={currentModel === 'eft'}
          onClose={() => {
            setCurrentModel(null);
          }}
          orderHandler={orderHandler}
          loadingHandler={() => {
            setLoading(true);
          }}
          clearCateringOrder={clearCateringOrder}
          cateringOrder={cateringOrder}
          totalPrice={totalPrice}
          setSaveCard={setSaveCard}
        />
      </div>
    </main>
  );
}

const mapStateToProps = state => ({
  user: state.user && state.user.user,
  receivingMethod: state.receivingMethod,
  cateringOrder: state.caterings.cateringOrder,
});
const mapDispatchToProps = {
  signoutRequest,
  showSignInModal,
  fetchCateringOrder,
  cateringOrderUpdate,
  cateringOrderFinalize,
  clearCateringOrder,
};

Checkout.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      preset: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        taxRate: PropTypes.number,
        presetLocation: PropTypes.objectOf({
          currentTime: PropTypes.string,
        }),
      }),
      deliveryPriceCents: PropTypes.number,
      checkoutData: PropTypes.arrayOf(PropTypes.shape({})),
      restaurantAddress: PropTypes.string,
    }),
  }).isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    companyName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    confirmed: PropTypes.bool,
    address2: PropTypes.string,
    defaultDeliveryInstructions: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  signoutRequest: PropTypes.func.isRequired,
  showSignInModal: PropTypes.func.isRequired,
  fetchCateringOrder: PropTypes.func.isRequired,
  cateringOrderUpdate: PropTypes.func.isRequired,
  cateringOrderFinalize: PropTypes.func.isRequired,
  clearCateringOrder: PropTypes.func.isRequired,
  cateringOrder: PropTypes.shape({
    saved_cards: PropTypes.arrayOf(
      PropTypes.objectOf({
        name: PropTypes.string,
        payment_method_id: PropTypes.string,
      }),
    ),
    purchaseId: PropTypes.number,
  }).isRequired,
  receivingMethod: PropTypes.string.isRequired,
};

const connectedHeader = connect(mapStateToProps, mapDispatchToProps)(Checkout);
export default withRouter(connectedHeader);
